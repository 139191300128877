import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import {
  DisplayPartListView,
  AddPart,
  CopyPart,
  RenamePart,
  MovePart,
  DisplayPlateListView,
  DisplayBuildListView,
  NewPlate,
  RenamePlate,
  CopyPlate,
  MovePlate,
  NewBuild,
  MoveBuild,
  DisplayBuildOptions,
  DisplayPartOptions,
  DisplayPlateOptions,
} from "../..";
import {
  onHideAllBuilds,
  onHideRecentBuilds,
  onSelectBuild,
  onShowAllBuilds,
  onShowArchivedBuilds,
  onShowRecentBuilds,
  onUnselectBuild,
  onUnselectBuilds,
} from "../../../Actions/Builds";
import {
  onUnselectFolder,
  onLoadParts,
  onLoadPlates,
  onLoadBuilds,
  onNavigateToBuildReviewPage,
  onHomePageTabChange,
  onNavigateToPlatesPage,
} from "../../../Actions/Home";
import {
  onShowRecentParts,
  onHideRecentParts,
  onShowAllParts,
  onHideAllParts,
  onShowArchivedParts,
  onHideArchivedParts,
  onHideSelectedParts,
  onShowSelectedParts,
  onSelectPart,
  onUnselectPart,
  onClearAddedParts,
  onUnselectParts,
  onFetchHighlightedPart,
} from "../../../Actions/Parts";
import { SortDirection, TabIndex } from "../../../Enums";
import onHighlightFolder from "../../../Actions/Folders/Highlight/highlightFolder";
import onHighlightFolderId from "../../../Actions/Folders/Highlight/highlightFolderId";
import {
  onSearchPartsTextChange,
  onSortPartsAsc,
  onSortPartsDesc,
} from "../../../Actions/Parts";
import {
  onHighlightPlate,
  onSearchPlatesTextChange,
  onSelectPlate,
  onSortPlatesAsc,
  onSortPlatesDesc,
  onUnselectPlate,
  onUnselectPlates,
} from "../../../Actions/Plates";
import {
  onSearchBuildsTextChange,
  onSortBuildsAsc,
  onSortBuildsDesc,
} from "../../../Actions/Builds";
import {
  IDisplayBuild,
  IDisplayFolder,
  IDisplayPart,
  IDisplayPlate,
  IPart,
  IPlate,
  IState,
} from "../../../Interfaces";
import {
  getDisplayBuilds,
  getDisplayFolders,
  getDisplayPart,
  getDisplayParts,
  getDisplayPlates,
} from "../../../Mappers";
import DisplayDashboardParts from "../Parts/DisplayDashboardParts";
import LoadingComponent from "../../../../LoadingComponent/LoadingComponent";
import DisplayDashboardPartCardView from "../Parts/DisplayDashboardPartCardView";
import DisplayDashboardPlateCardView from "../Plates/DisplayDashboardPlateCardView";
import DisplayDashboardBuildCardView from "../Builds/DisplayDashboardBuildCardView";
import DisplayDashboardFolder from "./DisplayDashboardFolder";
import NewBuildSuccess from "../../NewBuildSuccess";
import { DataGrid } from "@material-ui/data-grid";
import ProgressBar from "react-bootstrap/ProgressBar";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { NotificationManager } from "react-notifications";
import IPartInfo from "../../../../interfaces/IPartInfo";
import { Storage } from "aws-amplify";
import {
  buildSearchByCreatedAt,
  partsSearchByCreatedAt,
  plateSearchByCreatedAt,
} from "../../../../graphql/queries";
import { onFolderItemActionStatus } from '../../../Actions/Folders';

const Props = (state: IState, ownProps: any) => {
  const displayFolders = getDisplayFolders(state) as IDisplayFolder[];

  const totalFolders = (
    state.data.folders.sortDirection === SortDirection.ASC
      ? displayFolders.reverse()
      : displayFolders
  ) as IDisplayFolder[];

  const filteredFolders = totalFolders.filter(
    (df) => df.isFiltered
  ) as IDisplayFolder[];

  const selectedFolders = filteredFolders.filter(
    (ff) => ff.isSelected
  ) as IDisplayFolder[];

  const recentFolders = filteredFolders.filter(
    (ff) => !ff.isSelected && ff.isRecent
  ) as IDisplayFolder[];

  const allFolders = filteredFolders.filter(
    (ff) => !ff.isSelected && !ff.isArchived
  ) as IDisplayFolder[];

  const archivedFolders = filteredFolders.filter(
    (ff) => !ff.isSelected && ff.isArchived
  ) as IDisplayFolder[];

  const hasSelectedFolders = Boolean(selectedFolders.length > 0) as boolean;

  const displayParts = getDisplayParts(state) as IDisplayPart[];

  const totalParts = (
    state.data.builds.sortDirection === SortDirection.ASC
      ? displayParts.reverse()
      : displayParts
  ) as IDisplayPart[];

  const filteredParts = totalParts.filter(
    (dp) => dp.isFiltered
  ) as IDisplayPart[];

  let selectedParts = state.data.parts.selectedPartIds.map((id) =>
    filteredParts.find((lp) => lp.id === id)
  ) as IDisplayPart[];

  const getSelectedParts = (
    (state.data.parts.selectedParts || []) as IPart[]
  ).map((p) => getDisplayPart(p, state)) as IDisplayPart[];
  const displaySelectedParts = state.data.parts.selectedPartIds.map((id) =>
    getSelectedParts.find((lp) => lp.id === id)
  ) as IDisplayPart[];

  selectedParts = displaySelectedParts as IDisplayPart[];

  const recentParts = filteredParts.filter(
    (fp) => !fp.isArchived && fp.isRecent
  ) as IDisplayPart[];

  const allParts = filteredParts.filter(
    (fp) => !fp.isArchived
  ) as IDisplayPart[];

  const archivedParts = filteredParts.filter(
    (fp) => fp.isArchived
  ) as IDisplayPart[];

  const displayPlates = getDisplayPlates(state) as IDisplayPlate[];
  const totalPlates = (
    state.data.builds.sortDirection === SortDirection.ASC
      ? displayPlates.reverse()
      : displayPlates
  ) as IDisplayPlate[];

  const filteredPlates = totalPlates.filter(
    (dp) => dp.isFiltered
  ) as IDisplayPlate[];
  const allPlates = filteredPlates.filter(
    (fp) => !fp.isSelected && !fp.isArchived
  ) as IDisplayPlate[];
  const displayBuilds = getDisplayBuilds(state) as IDisplayBuild[];

  const totalBuilds = (
    state.data.builds.sortDirection === SortDirection.ASC
      ? displayBuilds.reverse()
      : displayBuilds
  ) as IDisplayBuild[];

  const filteredBuilds = totalBuilds.filter(
    (db) => db.isFiltered
  ) as IDisplayBuild[];

  const selectedBuilds = filteredBuilds.filter(
    (fb) => fb.isSelected
  ) as IDisplayBuild[];

  const recentBuilds = filteredBuilds.filter(
    (fb) => !fb.isSelected && !fb.isArchived && fb.isRecent
  ) as IDisplayBuild[];

  const allBuilds = filteredBuilds.filter(
    (fb) => !fb.isSelected && !fb.isArchived
  ) as IDisplayBuild[];

  const archivedBuilds = filteredBuilds.filter(
    (fb) => !fb.isSelected && fb.isArchived
  ) as IDisplayBuild[];

  const hasSelectedBuilds = Boolean(selectedBuilds.length > 0) as boolean;

  const showSelectedBuilds = Boolean(
    state.data.sections.recentBuilds.showItems
  ) as boolean;
  const showRecentBuilds = Boolean(
    state.data.sections.recentBuilds.showItems
  ) as boolean;
  const showAllBuilds = Boolean(
    state.data.sections.allBuilds.showItems
  ) as boolean;
  const showArchivedBuilds = Boolean(
    state.data.sections.archivedBuilds.showItems
  ) as boolean;

  const hasSelectedParts = Boolean(selectedParts.length > 0) as boolean;

  const showSelectedParts = Boolean(
    state.data.sections.selectedParts.showItems
  ) as boolean;
  const showRecentParts = Boolean(
    state.data.sections.recentParts.showItems
  ) as boolean;
  const showAllParts = Boolean(
    state.data.sections.allParts.showItems
  ) as boolean;
  const showArchivedParts = Boolean(
    state.data.sections.archivedParts.showItems
  ) as boolean;
  const showAllPlates = Boolean(
    state.data.sections.allPlates.showItems
  ) as boolean;

  const props = {
    showListView: state.data.info.showListView as boolean,
    highlightedFolder:state.data.folders.highlightedFolder as string,
    filteredFolders: filteredFolders as IDisplayFolder[],
    selectedFolders: selectedFolders as IDisplayFolder[],
    recentFolders: recentFolders as IDisplayFolder[],
    allFolders: allFolders as IDisplayFolder[],
    archivedFolders: archivedFolders as IDisplayFolder[],
    hasSelectedFolders: hasSelectedFolders as Boolean,
    filteredParts: filteredParts as IDisplayPart[],
    selectedParts: selectedParts.filter((e) => e != null) as IDisplayPart[],
    recentParts: recentParts as IDisplayPart[],
    allParts: allParts as IDisplayPart[],
    archivedParts: archivedParts as IDisplayPart[],
    hasSelectedParts: hasSelectedParts as Boolean,
    showSelectedParts: showSelectedParts as Boolean,
    showRecentParts: showRecentParts as Boolean,
    showAllParts: showAllParts as Boolean,
    showArchivedParts: showArchivedParts as Boolean,
    displayParts: ownProps.displayParts,
    filteredPlates: filteredPlates as IDisplayPlate[],
    allPlates: allPlates as IDisplayPlate[],

    showAllPlates: showAllPlates as Boolean,
    filteredBuilds: filteredBuilds as IDisplayBuild[],
    selectedBuilds: selectedBuilds as IDisplayBuild[],
    recentBuilds: recentBuilds as IDisplayBuild[],
    allBuilds: allBuilds as IDisplayBuild[],
    archivedBuilds: archivedBuilds as IDisplayBuild[],
    hasSelectedBuilds: hasSelectedBuilds as Boolean,
    showSelectedBuilds: showSelectedBuilds as Boolean,
    showRecentBuilds: showRecentBuilds as Boolean,
    showAllBuilds: showAllBuilds as Boolean,
    showArchivedBuilds: showArchivedBuilds as Boolean,
    loadedParts: state.data.parts.loadedParts,
    loadedPlates: state.data.plates.loadedPlates,
    loadedBuilds: state.data.builds.loadedBuilds,
    isLoadingComplete: (state.data.parts.isLoadingComplete &&
      state.data.builds.isLoadingComplete &&
      state.data.plates.isLoadingComplete) as boolean,
    searchText: state.data.parts.searchText as string,
    searchTextMatches: (state.data.parts.searchText ===
      state.data.builds.searchText &&
      state.data.builds.searchText === state.data.plates.searchText) as boolean,

    buildsSortDirection: state.data.builds.sortDirection,
    partsSortDirection: state.data.parts.sortDirection,
    platesSortDirection: state.data.plates.sortDirection,
    nextToken:
      state.data.parts.nextToken ||
      state.data.plates.nextToken ||
      state.data.builds.nextToken,
    displayFolders: ownProps.displayFolders,
    selectedPartIds: state.data.parts.selectedPartIds || [],
    selectedBuildIds: state.data.builds.selectedBuildIds || [],
    selectedPlateIds: state.data.plates.selectedPlateIds || [],
    creator: state.creator,
    enableBatchDelete: state.enableBatchDelete,
    isSearchEnabled: state.data.search.isSearchEnabled as boolean,
    selectedFolder: state.data.folders.highlightedFolder as string,
    isFolderActionStatus: state.data.folders.isFolderItemActionComplete as boolean,
  };

  return props;
};

const Actions = {
  onUnselectFolder: onUnselectFolder,
  onShowSelectedParts: onShowSelectedParts,
  onHideSelectedParts: onHideSelectedParts,
  onShowRecentParts: onShowRecentParts,
  onHideRecentParts: onHideRecentParts,
  onShowAllParts: onShowAllParts,
  onHideAllParts: onHideAllParts,
  onShowArchivedParts: onShowArchivedParts,
  onHideArchivedParts: onHideArchivedParts,
  onSelectPart: onSelectPart,
  onUnselectPart: onUnselectPart,
  onShowRecentBuilds: onShowRecentBuilds,
  onHideRecentBuilds: onHideRecentBuilds,
  onShowAllBuilds: onShowAllBuilds,
  onHideAllBuilds: onHideAllBuilds,
  onShowArchivedBuilds: onShowArchivedBuilds,
  onLoadParts: onLoadParts,
  onLoadPlates: onLoadPlates,
  onLoadBuilds: onLoadBuilds,
  onSearchPartsTextChange,
  onSortPartsAsc: onSortPartsAsc,
  onSortPartsDesc: onSortPartsDesc,
  onSearchPlatesTextChange: onSearchPlatesTextChange,
  onSortPlatesAsc: onSortPlatesAsc,
  onSortPlatesDesc: onSortPlatesDesc,
  onSearchBuildsTextChange: onSearchBuildsTextChange,
  onSortBuildsAsc: onSortBuildsAsc,
  onSortBuildsDesc: onSortBuildsDesc,
  onClearAddedParts: onClearAddedParts,
  onUnselectParts: onUnselectParts,
  onSelectBuild: onSelectBuild,
  onSelectPlate: onSelectPlate,
  onUnselectPlates: onUnselectPlates,
  onUnselectBuilds: onUnselectBuilds,
  onUnselectBuild: onUnselectBuild,
  onUnselectPlate: onUnselectPlate,
  onHomePageTabChange: onHomePageTabChange,
  onHighlightPlate: onHighlightPlate,
  onNavigateToPlatesPage: onNavigateToPlatesPage,
  onHighlightFolder: onHighlightFolder,
  onHighlightFolderId: onHighlightFolderId,
  onFolderItemActionStatus:onFolderItemActionStatus,
  onFetchHighlightedPart
};

const DashboardMethod = (props) => {
  const [searchedText, setSearchedText] = useState(undefined);
  const [selectedList, setSelectedList] = useState<any>([]);
  const [itemsInFolder, setItemsInFolder] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false); 

  const history = useHistory();
  const dispatch = useDispatch();

  //Fetching Parts Plates and Builds inside folder
  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
  useEffect(() => {
    const fetchItemsInsideFolder = async () => {
      if (props.selectedFolder !== "") {
        setLoading(true); 
        try {       
          const [partsInsideSelectedFolder] = await Promise.all([
            delay(4000).then(() => fetchPartResults(`${props.selectedFolder}/`)),
            // fetchPlateResults(`${props.selectedFolder}/`),
            // fetchBuildResults(`${props.selectedFolder}/`)
          ]);
      
          const combinedItems = [
            ...partsInsideSelectedFolder,
            // ...platesInsideSelectedFolder,
            // ...buildsInsideSelectedFolder,
          ];
          setItemsInFolder(combinedItems); // Update state with combined items
          props.onFolderItemActionStatus(false)
        } catch (error) {
          console.error('Error fetching items:', error);
        } finally {
          setLoading(false); 
        }
      }
    };

    fetchItemsInsideFolder();
  }, [props.selectedFolder,props.isFolderActionStatus]);


  //Fetching Part associated with folder
  const fetchPartResults = async (folderName: string): Promise<any[]> => {
    try {
      const nextToken = undefined;
      const requestVariables = {
        dumb: 1,
        sortDirection: "DESC",
        limit: 10000,
        nextToken: undefined,
        filter: {
          parts_search_string: { contains: folderName.toLowerCase() },
        },
        items: {
          searchString: folderName.toLowerCase(),
        },
      };

      let allPartSearchResults: any[] = []; // Explicitly defining the type here

      do {
        const response = (await API.graphql(
          graphqlOperation(partsSearchByCreatedAt, requestVariables)
        )) as any; // You can replace `any` with a more specific type if available

        const fetchedParts =
          response?.data?.partsSearchByCreatedAt?.items || [];
        const newNextToken =
          response?.data?.partsSearchByCreatedAt?.nextToken || undefined;

        allPartSearchResults = [...allPartSearchResults, ...fetchedParts];

        if (!newNextToken || fetchedParts.length === 0) {
          break;
        } else {
          requestVariables.nextToken = newNextToken;
        }
      } while (true);

      return allPartSearchResults;
    } catch (error) {
      // Type guard to check if it's an instance of GraphQL error
      const graphqlError = error as { errors?: { message: string }[] };
      const errorMessage = `Failed to fetch parts: ${
        graphqlError.errors?.[0]?.message || "Unknown error"
      }`;
      console.log(errorMessage);
      return []; // Return an empty array or handle as needed
    }
  };

  //Fetching Plate associated with folder
  const fetchPlateResults = async (folderName: string): Promise<any[]> => {
    try {
      const nextToken = undefined;
      const requestVariables = {
        dumb: 1,
        sortDirection: "DESC",
        limit: 10000,
        nextToken: undefined,
        filter: {
          plate_search_string: { contains: folderName.toLowerCase() },
        },
        items: {
          searchString: folderName.toLowerCase(),
        },
      };

      let allPlateSearchResults: any[] = [];

      do {
        const response = (await API.graphql(
          graphqlOperation(plateSearchByCreatedAt, requestVariables)
        )) as any;

        const fetchedPlates =
          response?.data?.plateSearchByCreatedAt?.items || [];
        const newNextToken =
          response?.data?.plateSearchByCreatedAt?.nextToken || undefined;

        allPlateSearchResults = [...allPlateSearchResults, ...fetchedPlates];

        if (!newNextToken || fetchedPlates.length === 0) {
          break;
        } else {
          requestVariables.nextToken = newNextToken;
        }
      } while (true);

      return allPlateSearchResults;
    } catch (error) {
      // Type guard to check if it's an instance of GraphQL error
      const graphqlError = error as { errors?: { message: string }[] };
      const errorMessage = `Failed to fetch plates: ${
        graphqlError.errors?.[0]?.message || "Unknown error"
      }`;
      console.log(errorMessage);
      return [];
    }
  };

  //Fetching Build associated with folder
  const fetchBuildResults = async (folderName: string): Promise<any[]> => {
    try {
      const nextToken = undefined;
      const requestVariables = {
        dumb: 1,
        sortDirection: "DESC",
        limit: 10000,
        nextToken: undefined,
        filter: {
          build_search_string: { contains: folderName.toLowerCase() },
        },
        items: {
          searchString: folderName.toLowerCase(),
        },
      };

      let allBuildSearchResults: any[] = [];

      do {
        const response = (await API.graphql(
          graphqlOperation(buildSearchByCreatedAt, requestVariables)
        )) as any;

        const fetchedBuilds =
          response?.data?.buildSearchByCreatedAt?.items || [];
        const newNextToken =
          response?.data?.buildSearchByCreatedAt?.nextToken || undefined;

        allBuildSearchResults = [...allBuildSearchResults, ...fetchedBuilds];

        if (!newNextToken || fetchedBuilds.length === 0) {
          break;
        } else {
          requestVariables.nextToken = newNextToken;
        }
      } while (true);

      return allBuildSearchResults;
    } catch (error) {
      // Type guard to check if it's an instance of GraphQL error
      const graphqlError = error as { errors?: { message: string }[] };
      const errorMessage = `Failed to fetch builds: ${
        graphqlError.errors?.[0]?.message || "Unknown error"
      }`;
      console.log(errorMessage);
      return [];
    }
  };




  const loadMore = async () => {
    props.onLoadParts();
    props.onLoadPlates();
    props.onLoadBuilds();
  };

  // search text side effect
  useEffect(() => {
    setSearchedText(props.searchText);
    if (!props.searchTextMatches) {
      props.onSearchPartsTextChange("");
      props.onSearchPlatesTextChange("");
      props.onSearchBuildsTextChange("");
      props.onLoadParts(true);
      props.onLoadPlates(true);
      props.onLoadBuilds(true);
    }
  }, [props.searchText]);

  const isConfigDisabled = useRef(false);

  // default side effect
  useEffect(() => {
    props.onClearAddedParts();
    props.onUnselectPart();
    Auth.currentAuthenticatedUser().then((user) => {
      const groups =
        user.signInUserSession.accessToken.payload["cognito:groups"] || [];
      if (groups == "Operator") {
        isConfigDisabled.current = true;
      } else {
        isConfigDisabled.current = false;
      }
    });
  }, []);

  // sorting side effect
  useEffect(() => {
    if (!props.hasSelectedFolders) {
      if (props.sorted) {
        if (props.partsSortDirection !== SortDirection.ASC) {
          console.log("Sorting parts");
          props.onSortPartsAsc();
          props.onLoadParts(true);
        }
        if (props.platesSortDirection !== SortDirection.ASC) {
          console.log("Sorting plates");
          props.onSortPlatesAsc();
          props.onLoadPlates(true);
        }
        if (props.buildsSortDirection !== SortDirection.ASC) {
          console.log("Sorting builds");
          props.onSortBuildsAsc();
          props.onLoadBuilds(true);
        }
      } else {
        if (props.partsSortDirection !== SortDirection.DESC) {
          console.log("Sorting parts");
          props.onSortPartsDesc();
          props.onLoadParts(true);
        }
        if (props.platesSortDirection !== SortDirection.DESC) {
          console.log("Sorting plates");
          props.onSortPlatesDesc();
          props.onLoadPlates(true);
        }
        if (props.buildsSortDirection !== SortDirection.DESC) {
          console.log("Sorting builds");
          props.onSortBuildsDesc();
          props.onLoadBuilds(true);
        }
      }
    } else {
      // front-end sorting
      buildCardView();
    }
  }, [props.sorted]);

  useEffect(() => {
    if (
      !props.selectedPlateIds.length &&
      !props.selectedPartIds.length &&
      !props.selectedBuildIds.length
    ) {
      setSelectedList([]);
    } else {
      props.enableBatchDelete &&
        setSelectedList([
          ...props.selectedPlateIds,
          ...props.selectedPartIds,
          ...props.selectedBuildIds,
        ]);
    }
  }, [
    props.showListView,
    props.selectedPlateIds,
    props.selectedPartIds,
    props.selectedBuildIds,
  ]);

  // Display selected parts section
  const partCardView = () => {
    return (
      <>
        <>
          <DisplayDashboardParts
            {...{
              label: "Selected Parts",
              displayFolders: [],
              displayParts: props.selectedParts,
              showOptions: true,
              showItems: true,
              onShowItems: props.onShowSelectedParts,
              onHideItems: props.onHideSelectedParts,
              isSelected: true,
              onUnselect: props.onUnselectPart,
              onSelect: props.onSelectPart,
            }}
          />
        </>
      </>
    );
  };

  const { loadedParts, loadedPlates, loadedBuilds, filter } = props;
  const lcfilter = filter?.toLowerCase();
  //  parts
  let idArr1 = loadedParts
    .map((val) => val.id)
    .filter((i) => !filter || i.toLowerCase().includes(lcfilter));
  idArr1 = [...new Set(idArr1)];
  const newLoadedParts = idArr1.map((val) => {
    return loadedParts.filter((value) => value.id == val)[0];
  });
  //  plates
  let idArr2 = loadedPlates
    .map((val) => val.id)
    .filter((i) => !filter || i.toLowerCase().includes(lcfilter));
  idArr2 = [...new Set(idArr2)];
  const newloadedPlates = idArr2.map((val) => {
    return loadedPlates.filter((value) => value.id == val)[0];
  });
  //  builds
  let idArr3 = loadedBuilds
    .map((val) => val.id)
    .filter((i) => !filter || i.toLowerCase().includes(lcfilter));
  idArr3 = [...new Set(idArr3)];
  const newloadedBuilds = idArr3.map((val) => {
    return loadedBuilds.filter((value) => value.id == val)[0];
  });


  const updatedParts = newLoadedParts.map((item) => ({
    ...item,
    itemname: "part",
  }));
  const updatedPlates = newloadedPlates.map((item) => ({
    ...item,
    itemname: "plate",
  }));
  const updatedBuilds = newloadedBuilds.map((item) => ({
    ...item,
    itemname: "build",
  }));
  const filteredParts = updatedParts.filter((part) => part.folders === null || part.folders.length === 0 || JSON.parse(part.folders[0]).name === "TestFolder");
  const totalItems = [...filteredParts, ...updatedPlates, ...updatedBuilds];

  const listViewRows = totalItems
    .sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    )
    .map((item) => {
      item.createdAt = (new Date(item.createdAt) as Date).toLocaleString();
      return item;
    });
  /* List View methods */
  totalItems.sort(
    (a, b) =>
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
  );
  if (props.sorted) {
    totalItems.sort().reverse();
  }

  const listViewColumns = [
    {
      field: "type",
      headerName: "Type",
      width: 150,
      sortable: false,
      renderCell: (params) => {
        if (
          params.row.hasOwnProperty("parts") &&
          params.row.hasOwnProperty("plates")
        ) {
          return <img className="icon build" alt="build" />;
        } else if (
          params.row.hasOwnProperty("parts") &&
          !params.row.hasOwnProperty("plates")
        ) {
          return <img className="icon plate" alt="plate" />;
        } else {
          return <img className="icon part" alt="part" />;
        }
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 400,
      sortable: true,
    },
    {
      field: "createdAt",
      headerName: "Imported Date",
      width: 250,
      sortable: true,
    },
    {
      field: "created_by",
      headerName: "Owner",
      width: 300,
      sortable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 350,
      sortable: false,
      renderCell: (params) => {
        let buildProgress = 0;
        if (
          params.row.current_status === "processing" ||
          params.row.current_status === "Progress"
        ) {
          buildProgress = params.row.build_result
            ? parseFloat(params.row.build_result.replace("%", "").split(" ")[1])
            : 0;
          if (isNaN(buildProgress)) {
            buildProgress = 0;
          }
        }
        if (
          params.row.hasOwnProperty("parts") &&
          params.row.hasOwnProperty("plates")
        ) {
          if (
            params.row.current_status === "completed" ||
            params.row.status === "completed"
          ) {
            return (
              <span className="capitalise-text font-weight-bold">
                <img
                  src="/img/slicing-completed.svg"
                  alt="Completed Status Icon"
                  className="list-view-build-img"
                />
                {" Slicing Completed"}
              </span>
            );
          }
          if (
            params.row.current_status === "failed" ||
            params.row.status === "failed"
          ) {
            return (
              <span className="capitalise-text font-weight-bold">
                <img
                  src="/img/slicing-failed.svg"
                  alt="Failed Status Icon"
                  className="list-view-build-img"
                />
                {" " + "Slicing Failed"}
              </span>
            );
          }
          if (
            params.row.current_status === "canceled" ||
            params.row.status === "canceled"
          ) {
            return (
              <span className="capitalise-text font-weight-bold">
                <img
                  src="/img/slicing-failed.svg"
                  alt="Canceled Status Icon"
                  className="list-view-build-img"
                />
                {" " + "Slicing Canceled"}
              </span>
            );
          }
          if (
            params.row.current_status === "processing" ||
            params.row.current_status === "waiting" ||
            params.row.status === "processing" ||
            params.row.status === "waiting" ||
            params.row.current_status === "Progress"
          ) {
            return (
              <div className="progress-flex-section">
                {params.row.current_status === "processing" ||
                params.row.current_status === "Progress" ? (
                  <span className="pl-0">{`${buildProgress}%`}</span>
                ) : (
                  <span className="pl-0">Initializing Build</span>
                )}
                <ProgressBar variant="success" now={buildProgress} />
              </div>
            );
          }
        }
      },
    },
    {
      field: "folder",
      headerName: "Folder",
      width: 200,
      sortable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,
      renderCell: (params) => {
        return (
          <div
            className="display-list-more-action"
            onClick={(e) => { 
              e.stopPropagation();
              props.onFetchHighlightedPart(params.id);
            }}
          >
            {params.row.hasOwnProperty("parts") &&
            params.row.hasOwnProperty("plates") ? (
              <DisplayBuildOptions displayBuild={params.row} />
            ) : params.row.hasOwnProperty("parts") &&
              !params.row.hasOwnProperty("plates") ? (
              <DisplayPlateOptions displayPlate={params.row} />
            ) : (
              <DisplayPartOptions displayPart={params.row} />
            )}
          </div>
        );
      },
    },
  ];

  const editPlate = async (plateToBeEdited) => {
    if (
      plateToBeEdited &&
      plateToBeEdited.parts[0] &&
      JSON.parse(plateToBeEdited.parts[0]).Key ===
        `PlatePartsFiles/${plateToBeEdited.id}.json`
    ) {
      const path = `PlatePartsFiles/${plateToBeEdited.id}.json`;
      const AWSBucketParam = {
        Bucket: Storage["_config"]["AWSS3"]["bucket"],
        Key: path,
        CacheControl: "no-cache", // or 'max-age=0'
      };
      try {
        const getResult = await Storage.get(AWSBucketParam.Key, {
          download: true,
          cacheControl: "no-cache",
        });
        plateToBeEdited.parts = JSON.parse(
          await (getResult.Body as any).text()
        );
      } catch (error: any) {
        NotificationManager.error(
          "An error occurred during the plate editing process"
        );
      }
    }
  };

  const handleListRowClick = async (params) => {
    if (params.row.itemname === "build") {
      if (
        params.row.current_status &&
        (params.row.current_status.toLowerCase().includes("completed") ||
          (params.row.current_status.toLowerCase().includes("fail") &&
            !isConfigDisabled.current))
      ) {
        dispatch(onNavigateToBuildReviewPage(params.row.id, history));
      }
    }
    if (params.row.itemname === "plate") {
      props.onHomePageTabChange(TabIndex.PARTS);
      props.onHighlightPlate(params.row.id);
      const plateToBeEdited = props.loadedPlates.find(
        (lp) => lp.id === params.row.id
      ) as IPlate;
      await editPlate(plateToBeEdited);
      const partIds = plateToBeEdited.parts
        ?.filter(Boolean)
        .map((stringifiedPart) => JSON.parse(stringifiedPart))
        .filter(Boolean)
        .map((part) => part?.properties.PartID)
        .filter(Boolean) as string[];
      const parts = plateToBeEdited.parts
        .filter(Boolean)
        .map((stringifiedPart) => JSON.parse(stringifiedPart))
        .filter(Boolean) as IPartInfo[];
      const partConfigurationIds = parts
        .map((part) => part.properties.PartConfig?.originalJson?.id)
        .filter((c) => c);
      const partSlicerConfigurationIds = parts
        .map((part) => part.properties.SlicerConfig?.originalJson?.id)
        .filter((c) => c);
      const plateConfigurationIds = [
        plateToBeEdited.machineId,
        plateToBeEdited.millConfigId,
        plateToBeEdited.materialConfigId,
        plateToBeEdited.recipeId,
      ];

      const ids = {
        partIds,
        parts,
        partConfigurationIds,
        partSlicerConfigurationIds,
        plateConfigurationIds,
      };

      props.onNavigateToPlatesPage(ids, history, false);
    }
  };

  const handleCheckBoxSelect = (ids) => {
    let selectedIds = ids;
    const created_by =
      listViewRows.filter((row) => row.id.toString() === ids[ids.length - 1])[0]
        ?.created_by ||
      listViewRows.filter((row) => row.id.toString() === ids[ids.length - 1])[0]
        ?.creator ||
      ""; // get created value for recently selected item.
    // find removed elements
    const removedItem = selectedList.filter((item) => ids.indexOf(item) === -1);

    if (removedItem.length) {
      const removedData = listViewRows.filter(
        (item) => item.id === removedItem[0]
      );
      if (removedData.length) {
        if (removedData[0].itemname === "build") {
          props.onUnselectBuild(removedData[0].id);
        } else if (removedData[0].itemname === "part") {
          props.onUnselectPart(removedData[0].id);
        } else {
          props.onUnselectPlate(removedData[0].id);
        }
      }
    } else {
      // find selected item is part/plate/build
      const findItem = listViewRows.filter(
        (item) => item.id === ids[ids.length - 1]
      );
      if (findItem[0].itemname === "part") {
        if (created_by !== props.creator && props.enableBatchDelete) {
          NotificationManager.error(
            "You lack the authorization to delete parts created by other users."
          );
          selectedIds.pop(); // remove recently selected item from the grid
        } else {
          props.onSelectPart(findItem[0].id);
        }
      } else if (findItem[0].itemname === "build") {
        if (created_by !== props.creator && props.enableBatchDelete) {
          NotificationManager.error(
            "You lack the authorization to delete builds created by other users."
          );
          selectedIds.pop(); // remove recently selected item from the grid
        } else {
          props.onSelectBuild(findItem[0].id);
        }
      } else {
        if (created_by !== props.creator && props.enableBatchDelete) {
          NotificationManager.error(
            "You lack the authorization to delete plates created by other users."
          );
          selectedIds.pop(); // remove recently selected item from the grid
        } else {
          props.onSelectPlate(findItem[0].id);
        }
      }
    }
    if (!ids.length) {
      props.onUnselectParts();
      props.onUnselectBuilds();
      props.onUnselectPlates();
    }
    setSelectedList(props.enableBatchDelete ? selectedIds : []);
  };
  // Function to add the class name in each row of the grid ( To disable the build and plate checkbox )
  const getRowClassName = (params) => {
    if (params.row.itemname === "part") {
      return "enable-checkbox-row";
    } else if (params.row.itemname !== "part" && props.enableBatchDelete) {
      return "enable-checkbox-row";
    }
    return "";
  };

  /* List View methods end */

  const selectedFolderView = (selectedFolder) => {
    const loadedList = [
      ...selectedFolder.displayBuilds,
      ...selectedFolder.displayPlates,
    ];

   
    // const hasFolderItem = itemsInFolder.length !==0 ?itemsInFolder : [];
    
    const hasFolderItem=loadedList.filter((db) =>
      db.folders.some((f) => f === selectedFolder["name"])
    );
    hasFolderItem.push(...itemsInFolder)
 
    
    const filterByDate = hasFolderItem.map(
      (filteredItem) => filteredItem.createdAt
    );
    const sortedList: any = [];

    hasFolderItem.forEach((item: any) => {
      if (item.hasOwnProperty("parts") && item.hasOwnProperty("plates")) {
        item.type = "builds";
      } else if (
        item.hasOwnProperty("parts") &&
        !item.hasOwnProperty("plates")
      ) {
        item.type = "plates";
      } else {
        item.type = "parts";
      }
      let checkIndex = !props.sorted
        ? filterByDate.indexOf(item.createdAt)
        : filterByDate.sort().indexOf(item.createdAt);
      sortedList[checkIndex] = item;
    });

    return (
      <>
        <dl className={`${props.showListView ? "grid-view" : ""}`}>
          <dd>
            <div className="options w-100 mt-3">
              <div className="back option">
                <div
                  className="backIcon"
                  onClick={() => {
                    props.onUnselectFolder(selectedFolder["id"]);
                    props.onHighlightFolder("");
                    props.onHighlightFolderId("");
                  }}
                ></div>
                <label>{props.highlightedFolder}</label>
              </div>
            </div>
            {props.showListView && sortedList.length ? (
              <>
                <div className="display-list">
                  <div className="display-list-row">
                    <div className="display-list-header">
                      <label>Type</label>
                    </div>
                    <div className="display-list-header">
                      <label>Name</label>
                    </div>
                    <div className="display-list-header">
                      <label>Created At</label>
                    </div>
                    <div className="display-list-header">
                      <label>Owner</label>
                    </div>
                    {/* <div className="display-list-header">
                      <label>Status</label>
                    </div>
                    <div className="display-list-header">
                      <label>Folder</label>
                    </div> */}
                    <div className="display-list-header">
                      <label>Actions</label>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
      
         {/* Showing the folder items in list view and Card view */}

            {sortedList.length ? sortedList.map((sortedItem, i) => {      
                        
              //parts inside folder view
              if (sortedItem.type === "parts") {
                return props.showListView ? (
                  <DisplayPartListView key={i} displayPart={sortedItem} />
                ) : (
                  <DisplayDashboardPartCardView
                    {...{
                      key: i,
                      displayPart: sortedItem,
                      isSelected: false,
                      onUnselect: props.onUnselectPart,
                      onSelect: props.onSelectPart,
                    }}
                  />
                );
              } else if (sortedItem.type === "plates") {
                return props.showListView ? (
                  <DisplayPlateListView key={i} displayPlate={sortedItem} />
                ) : (
                  <DisplayDashboardPlateCardView
                    key={i}
                    displayPlate={sortedItem}
                  />
                );
              } else {
                return props.showListView ? (
                  <DisplayBuildListView key={i} displayBuild={sortedItem} />
                ) : (
                  <DisplayDashboardBuildCardView
                    key={i}
                    displayBuild={sortedItem}
                  />
                );
              }
            }) : null}
          </dd>
        </dl>
      </>
    );
  };
  const buildCardView = () => {
    return (
      <>
        {props.hasSelectedFolders
          ? props.selectedFolders.map((selectedFolder, index) => (
              <div key={index}>{selectedFolderView(selectedFolder)}</div>
            ))
          : dashboardView()}
      </>
    );
  };
  const dashboardView = () => {
    return (
      <>
        <dl className={`${props.showListView ? "grid-view" : ""}`}>
          <dd>
            {props.allFolders.map((df, i) => {
              return (
                <DisplayDashboardFolder
                  key={i}
                  displayFolder={df}
                  showParts={true}
                  showPlates={true}
                  showBuilds={true}
                />
              );
            })}
          </dd>
          <dd>
            {props.showListView && (
              <>
                <DataGrid
                  columns={listViewColumns}
                  rows={listViewRows}
                  autoHeight
                  autoPageSize
                  disableSelectionOnClick
                  disableColumnMenu
                  className="list-view-grid"
                  hideFooterPagination
                  onRowClick={handleListRowClick}
                  checkboxSelection
                  onSelectionModelChange={(id) => {
                    handleCheckBoxSelect(id);
                  }}
                  selectionModel={selectedList} //using state variable to enable the checked items
                  getRowClassName={getRowClassName}
                />
              </>
            )}
            {!props.showListView &&
              totalItems?.map((item, i) => {
                if (
                  item.itemname === "part" &&
                  (item.folders === null || item?.folders?.length === 0 || JSON.parse(item.folders[0]).name === "TestFolder")
                ) {
                  return (
                    <DisplayDashboardPartCardView
                      {...{
                        key: i,
                        displayPart: item,
                        isSelected: false,
                        onUnselect: props.onUnselectPart,
                        onSelect: props.onSelectPart,
                      }}
                      // key={i} displayPart={item}
                    />
                  );
                }
                if (item.itemname === "plate") {
                  return (
                    <DisplayDashboardPlateCardView
                      key={i}
                      displayPlate={item}
                    />
                  );
                }
                if (item.itemname === "build") {
                  return (
                    <DisplayDashboardBuildCardView
                      key={i}
                      displayBuild={item}
                    />
                  );
                }
              })}
          </dd>
        </dl>
      </>
    );
  };

  if (
    totalItems.length === 0 &&
    props.isLoadingComplete &&
    props.isSearchEnabled
  ) {
    return (
      <div className="d-block w-100 text-center search-message mt-5">
        <img src="/no-result-found.svg" alt="no-search-result" />
        <p className="justify-content-center p-4">
          No result found in the name of <b>{props.searchText}</b>
        </p>
      </div>
    );
  }

  if (
    totalItems.length === 0 &&
    props.isLoadingComplete &&
    !props.isSearchEnabled
  ) {
    return (
      <div className="d-block w-100 text-center search-message position-absolute top50">
        <img src="/no-result-found.svg" alt="no-search-result" />
        <p className="justify-content-center p-4">No Data Found</p>
      </div>
    );
  }

  return (
    <>
      <LoadingComponent visible={!props.isLoadingComplete|| loading } />
      {!props.enableBatchDelete && partCardView()}
      {props.showListView && !props.hasSelectedFolders
        ? dashboardView()
        : buildCardView()}
      {/* {
                dashboardView()
            } */}
      {
        props.nextToken && !props.hasSelectedFolders && props.isLoadingComplete && (
          <div className="d-block w-100 text-center pb-4">
            <button
              onClick={() => loadMore()}
              className="btn btn-primary justify-content-center"
            >
              Load More<span className="btn-right-arrow"></span>
            </button>
          </div>
        )
       
      }
      <AddPart />
      <NewPlate />
      <RenamePlate />
      <CopyPlate />
      <MovePlate />
      <RenamePart />
      <CopyPart />
      <MovePart />
      <NewBuild />
      <MoveBuild />
      <NewBuildSuccess />
    </>
  );
};

const Dashboard = withRouter(connect(Props, Actions)(DashboardMethod));

export default Dashboard;
