import React, { useState, FunctionComponent, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./TopNav.scss";
import "../MVPSideNav/SideNav.scss";
import { Auth } from "aws-amplify";
import MVPBuilds from "../MVPBuilds/MVPBuilds";
import { SignOut } from "aws-amplify-react";
import { useDispatch } from "react-redux";
import { onHomePageTabChange } from "../Beta/Actions/Home";
import { TabIndex } from "../Beta/Enums";
import { onUnselectParts } from "../Beta/Actions/Parts";
import API, { graphqlOperation } from "@aws-amplify/api";
import { getRole } from "../graphql/queries";
import { onHighlightFolder, onHighlightFolderId } from "../Beta/Actions/Folders";

// var isRoleOperator = false;
const TopNav: FunctionComponent<any> = (props: { signOut: any }) => {
  const [isConfigDisabled, setIsConfigDiabled] = useState<Boolean>(false);
  const [showBuild, setShowBuild] = useState<boolean>(false);
  const history = useHistory();
  const [userName, setUserName] = useState("");
  const [group, setGroup] = useState("");
  const dispatch = useDispatch();
  const [isAdminPermission, setIsAdminPermission] = useState(false);
  const [isRoleOperator, setIsRoleOperator] = useState(false);

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(async (user) => {
      setUserName(user.username);
      console.log(user, "loggedinuser");
      const groups =
        user.signInUserSession.accessToken?.payload["cognito:groups"] || [];
        setGroup(groups[0] || "");
        if (groups[0] !== "SuperAdmin") {
          const response = (await API.graphql(
            graphqlOperation(getRole, { id: groups[0] })
          )) as any;
          const { data } = response;
          if (response && data) {
            if (groups[0] === "Operator") {
              setIsConfigDiabled(true);
              setIsRoleOperator(true);
            } else {
              setIsConfigDiabled(false);
              setIsRoleOperator(false);
            }
            // setIsAdminPermission(data?.getRole?.permissions?.userManagement ? true : false); 
            setIsAdminPermission(groups[0] === "Admin" || groups[0] === "admin" ? true : false);
          }
        }
    });
  }, []);


  return (
    <div className="topNavSection">
      <div
        className={
          window.location.href.includes("/dashboard/home") ||
            window.location.href.includes("/dashboard/BuildPreperation") ||
            window.location.href.includes("/dashboard/BuildReview/Parts") ||
            window.location.href.includes("dashboard/ReviewPage/") 

            ? "each-section vertical-active"
            : "each-section"
        }
        onClick={() => {
          dispatch(onHomePageTabChange(TabIndex.ALL));
          dispatch(onUnselectParts());
          dispatch(onHighlightFolder(""));
          dispatch(onHighlightFolderId(""));
          history.push("/dashboard/home");
        }}
      >
        <div className="content">
          <div className="each-section-icon">
            <span className="each-section-label">Prep</span>
          </div>
        </div>
      </div>
      <div
        className={
          window.location.href.includes("/dashboard/print") ||
            window.location.href.includes("/dashboard/printReview")
            ? "each-section vertical-active"
            : "each-section"
        }
        onClick={() => {
          history.push("/dashboard/print");
        }}
      >
       
        <div className="content">
          <div className="each-section-icon">
            <span className="each-section-label">Print</span>
          </div>
        </div>
      </div>
      {/*<div
        className={
          window.location.href.includes("#") ||
            window.location.href.includes("/dashboard/printReview")
            ? "each-section vertical-active"
            : "each-section"
        }

      >
        {!isRoleOperator ?
        <div className="content">
          <div className="each-section-icon">
            <span className="each-section-label">Sinter</span>
          </div>
        </div>:null}
        </div>*/}


      <div
        className={
          window.location.href.includes("#") ||
          window.location.href.includes("/dashboard/analytics")
            ? "each-section vertical-active"
            : "each-section"
        }
        onClick={() => {
          history.push("/dashboard/analytics");
        }}
      >
        <div className="content">
          <div className="each-section-icon">
            <span className="each-section-label">Analytics</span>
          </div>

        </div>
        {/* <div className='highlighter'></div> */}
      </div>

        
      {!isRoleOperator &&
      <div
        className={
          window.location.href.includes("#") ||
          window.location.href.includes("/dashboard/configuration")
            ? "each-section vertical-active"
            : "each-section"
        }
        onClick={() => {
          if (isConfigDisabled) return;
          history.push("/dashboard/configuration");
        }}
      >
        <div className="content">
          <div className="each-section-icon">
            <span className="each-section-label">Configure</span>
          </div>

        </div>
        {/* <div className='highlighter'></div> */}
      </div>}
      <div
        className={
          // window.location.href.includes("#") ||
          window.location.href.includes("/dashboard/documents")
            ? "each-section vertical-active"
            : "each-section"
        }
        onClick={() => {
          console.log("Navigating to /dashboard/documents");
          history.push("/dashboard/documents");
        }}
      >
       
        <div className="content">
          <div className="each-section-icon">
            <span className="each-section-label">Help</span>
          </div>
        </div>
      </div>

      {(group === "SuperAdmin" || group === "superAdmin") || (isAdminPermission) ? <div
        className={
          // window.location.href.includes("#") ||
          window.location.href.includes("/dashboard/user")
            ? "each-section vertical-active"
            : "each-section"
        }
        onClick={() => {
          console.log("Navigating to /dashboard/user");
          history.push("/dashboard/user");
        }}
      >
       
        <div className="content">
          <div className="each-section-icon">
            <span className="each-section-label">Admin</span>
          </div>
        </div>
      </div> : null}

      <div className="flex-space"></div>
      <div className="logo">
        <img src="/img/mantleLogo.svg" alt="mantleLogo" />
      </div>

      <div className="separator"></div>
      {/*{!isRoleOperator ?
      <div className="separator">

      </div>: null}

      {!isRoleOperator ?
      <div className="logo-t">LAVA</div>:null}

      <div className="flex-space"></div>*/}

      {/*<div
        className="on-process"
        onClick={() => setShowBuild(!showBuild)}
        ></div>*/}
      <div className="logo">
        <img src="/img/trueshape_logo.svg" alt="trueshapeLogo" />
      </div>


      <nav role="navigation">
        <div id="menu-toggle">
          <input type="checkbox" />

          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>

          <ul id="menu">
            <li><img src="/user-icon.png" />Welcome <span className="user-name">{userName}</span></li>
            {/* <li ><img src="/account-icon.png" />Account</li>
            <li><img src="/settings-icon.png" />Settings</li> */}
            {/* <li><img src="/help-icon.png" />Help</li> */}
            <li onClick={() => history.push('/dashboard/documents')}><img src="/documents-icon.svg" />Help</li>
            <li><a onClick={() => Auth.signOut()}><img src="/signout-icon.png" />Sign Out</a></li>
            {/* <div className="welcome-message">
              <label className="message">
                Welcome <span className="user-name">{userName}</span>
              </label>
            </div>

            <div className="on-signout" onClick={() => Auth.signOut()}>
              <img src="/img/signout.svg" alt="signoutLogo" />
              <br />
              <span className="sign-out-text">sign out</span>
            </div> */}
          </ul>
        </div>
      </nav>

      <>
        {showBuild ? (
          <div className="recentBuildList">
            <MVPBuilds pageView={"short"} />
          </div>
        ) : null}
      </>
    </div>
  );
};

export default TopNav;
