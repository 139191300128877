import API, { graphqlOperation } from "@aws-amplify/api";

import { createParts, deleteParts } from "../../../../graphql/mutations";

import ACTION_TYPES from "../../../actionTypes";
import { IPart, IMovePartModalStatus, IState } from "../../../Interfaces";
import { NotificationManager } from "react-notifications";
import deletePart from "../Delete/deletePart";
import { getParts } from "../../../../graphql/queries";
import { Storage } from "aws-amplify";

const onMovePartInit = () => {
  return {
    type: ACTION_TYPES.PART.MOVE.INIT,
    payload: null,
  };
};

const onMovePartSuccess = (movedPart) => {
  return {
    type: ACTION_TYPES.PART.MOVE.SUCCESS,
    payload: movedPart,
  };
};

const onMovePartFail = (errorMessage: string) => {
  NotificationManager.error("Failure on Move Part");
  console.error("Failure on Move Part - ", errorMessage);
  return {
    type: ACTION_TYPES.PART.MOVE.FAIL,
    payload: errorMessage,
  };
};

const onMovePartDone = () => {
  return {
    type: ACTION_TYPES.PART.MOVE.DONE,
    payload: null,
  };
};
const onFolderItemActionStatus = (status) => {
  return {
    type: ACTION_TYPES.FOLDER.ITEM_ACTION_STATUS.ACTION,
    payload: status,
  };
};

const onMovePart = () => {
  return async (dispatch, getState) => {
    dispatch(onMovePartInit());
    const state = getState() as IState;

    const movePartModalStatus = state.data.modals
      .movePartModalStatus as IMovePartModalStatus;

    const isFolderNameEmpty = !movePartModalStatus.inputText as boolean;

    try {
      const state = getState() as IState;
      try {

        const getSelectedPart = await API.graphql(
          graphqlOperation(getParts, {
            id: state.data.parts.highlightedPartId,
          })
        );
        const _version = (getSelectedPart as any).data.getParts._version;
        const prevId = (getSelectedPart as any).data.getParts.id;
        const getSelectedPartResult = (getSelectedPart as any).data.getParts;
        const filesString = getSelectedPartResult.files;
          if (getSelectedPartResult) {
            const newPart = getSelectedPartResult as any;
            // move part to new folder in s3
            const newFilesObject = {}
            for(const [key, value] of Object.entries(JSON.parse((getSelectedPart as any).data.getParts.files))){
              const oldFilePath = value as string;
              const spilitedDataValue = oldFilePath.split('/');
              const newFilePath = isFolderNameEmpty ? `FileStorage/Parts/${spilitedDataValue[spilitedDataValue.length - 1]}`
              : `FileStorage/Parts/${movePartModalStatus.inputText}/${spilitedDataValue[spilitedDataValue.length - 1]}`;
              await Storage.copy(
                { key: oldFilePath},
                { key: newFilePath }
              )
              newFilesObject[key] = newFilePath;
            }
            const folderName = JSON.stringify({
              name: movePartModalStatus.inputText,
            }) as string;
            newPart.id = isFolderNameEmpty
              ? newPart.name
              : `${movePartModalStatus.inputText}/${newPart.name}`;
            newPart.name = newPart.name;
            newPart.parts_search_string = `${
              movePartModalStatus.inputText
            }/${newPart.name.toLowerCase()}`;

            delete newPart._deleted;
            delete newPart.archive;
            delete newPart._version;
            delete newPart._lastChangedAt;
            delete newPart.createdAt;
            delete newPart.updatedAt;
            newPart.hasBuilds = false;
            newPart.hasPlates = false;
            newPart.builds = [];
            newPart.plates = [];
            // newPart.creator = state.creator as string;
            // newPart.created_at = new Date().toISOString();
            newPart.modifier = state.creator as string;
            newPart.modified_at = new Date().toISOString();
            newPart.parts_search_string = isFolderNameEmpty
              ? newPart.name
              : `${movePartModalStatus.inputText}/${newPart.name}`.toLowerCase();
            newPart.files = JSON.stringify(newFilesObject);
            newPart.folders = isFolderNameEmpty
              ? []
              : ([folderName] as string[]);
            newPart.dumb = 1;
            const variables = {
              input: newPart,
            };
            try {
              const newPartresult = await API.graphql(
                graphqlOperation(createParts, variables)
              );
              if(newPartresult){
                const deletePartVariables = {
                  input: {
                    id: prevId,
                    _version: _version,
                  },
                };
                try {
                  for(const [key, value] of Object.entries(JSON.parse(filesString))){
                    const oldFilePath = value as string;
                    await Storage.remove(oldFilePath);
                  }
                  await API.graphql(
                    graphqlOperation(deleteParts, deletePartVariables)
                  );
                  NotificationManager.success(`Part moved successfully.`);
                }
                catch (error) {
                  NotificationManager.error("Error");
                  console.error("Error deleting part", error);
                }
              }
              const addedPart = (newPartresult as any)?.data
                ?.createParts as IPart;
              dispatch(onMovePartSuccess(addedPart));
              dispatch(onMovePartDone());
              if (state.data.folders.highlightedFolder !== "") {
                dispatch(onFolderItemActionStatus(true));
              }
            } catch (ex: any) {
              NotificationManager.error("Failed to move part");
              console.error("Exception ", ex, " updating failed");
            }
          }
      } catch (graphqlError) {
        const errorMessage = `${Object(
          (graphqlError as any)?.errors?.[0]?.message
        ).toString()}`;
        console.error(errorMessage);
      }
    } catch (graphqlError) {
      const errorMessage = `Move part failed: ${Object(
        (graphqlError as any)?.errors?.[0]?.message
      ).toString()}`;
      dispatch(onMovePartFail(errorMessage));
      dispatch(onMovePartDone());
    }
  };
};

export default onMovePart;
