import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import IPartInfo from "../../../interfaces/IPartInfo";
import { onNavigateToPlatesPage } from "../../Actions/Home";
import { IDisplayPlate, IPlate, IState } from "../../Interfaces";
import { onHighlightPlate } from '../../Actions/Plates';
import { onHomePageTabChange } from '../../Actions/Home';
import DisplayPlateOptions from "../Prepare/Plates/DisplayPlateOptions";
import moment from "moment";
import { API, graphqlOperation } from "aws-amplify";
import { NotificationManager } from "react-notifications";
import { getPlate } from "../../../graphql/queries";
import { TabIndex } from "../../Enums";
import onGetHighlightedPlate from "../../Actions/Search/highlightPlate";
import { Storage } from "aws-amplify";


const Props = (state: IState, ownProps: any) => {
	const isSearchEnabled = state.data.search.isSearchEnabled as boolean;
    const highlightPlate = state.data.search.highlightedPlateData as IPlate;
	const loadedPlates = state.data.plates.loadedPlates;
	const props = {
		isSearchEnabled: isSearchEnabled as boolean,
        highlightPlate: highlightPlate as IPlate,
		loadedPlates: loadedPlates as IPlate[],
	};

	return props;
};

const Actions = {
	onHighlightPlate: onHighlightPlate,
	onNavigateToPlatesPage: onNavigateToPlatesPage,
	onHomePageTabChange: onHomePageTabChange,
	onGetHighlightedPlate: onGetHighlightedPlate,
};

class Component extends React.Component<any, any> {
  isProcessing: boolean = false;
  constructor(props: any) {
    super(props);
    this.state = {
      selectedPlate: {},
    };
  }
  handlePlateCardClickHandler = async (id) => {
    if (this.isProcessing) return;
    this.isProcessing = true;
    if (this.isProcessing) {
      this.props.onHighlightPlate(id);
      try {
        const result = await API.graphql(
          graphqlOperation(getPlate, {
            id: id,
          })
        );
        const getSelectedPlateResult = (result as any)?.data?.getPlate;

        if (getSelectedPlateResult) {
          const plateToBeEdited = getSelectedPlateResult as IPlate;
          if (
            plateToBeEdited &&
            plateToBeEdited.parts[0] &&
            JSON.parse(plateToBeEdited.parts[0]).Key ===
              `PlatePartsFiles/${plateToBeEdited.id}.json`
          ) {
            const path = `PlatePartsFiles/${plateToBeEdited.id}.json`;
            const AWSBucketParam = {
              Bucket: Storage["_config"]["AWSS3"]["bucket"],
              Key: path,
              CacheControl: "no-cache", // or 'max-age=0'
            };
            try {
              const getResult = await Storage.get(AWSBucketParam.Key, {
                download: true,
                cacheControl: "no-cache",
              });
              plateToBeEdited.parts = JSON.parse(
                await (getResult.Body as any).text()
              );
            } catch (error: any) {
              NotificationManager.error(
                "An error occurred during the plate editing process"
              );
              console.error(
                "An error occurred during the plate editing process:",
                error.message
              );
            }
          }
          const partIds = plateToBeEdited.parts
            .filter(Boolean)
            .map((stringifiedPart) => JSON.parse(stringifiedPart))
            .filter(Boolean)
            .map((part) => part.properties.PartID)
            .filter(Boolean) as string[];
          const parts = plateToBeEdited.parts
            .filter(Boolean)
            .map((stringifiedPart) => JSON.parse(stringifiedPart))
            .filter(Boolean) as IPartInfo[];
          const partConfigurationIds = parts
            .map((part) => part.properties.PartConfig?.originalJson?.id)
            .filter((c) => c);
          const partSlicerConfigurationIds = parts
            .map((part) => part.properties.SlicerConfig?.originalJson?.id)
            .filter((c) => c);
          const plateConfigurationIds = [
            plateToBeEdited.machineId,
            plateToBeEdited.millConfigId,
            plateToBeEdited.materialConfigId,
            plateToBeEdited.recipeId,
          ];
          const ids = {
            partIds,
            parts,
            partConfigurationIds,
            partSlicerConfigurationIds,
            plateConfigurationIds,
          };
          this.props.onNavigateToPlatesPage(ids, this.props.history, false);
          this.props.onHomePageTabChange(TabIndex.PARTS);
        }
      } catch (graphqlError) {
        const errorMessage = `${Object(
          (graphqlError as any)?.errors?.[0]?.message
        ).toString()}`;
        console.error(errorMessage);
        NotificationManager.error(
          `Not able to fetch selected Plate due to ${errorMessage} `
        );
      }
    }
  };

  public render() {
    return (
      <>
        <div
          className={`p-0 plate-card`}
          id={this.props.plate.id}
          key={this.props.plate.id}
          onClick={(e) => {
            this.handlePlateCardClickHandler(this.props.plate.id);
            this.props.onGetHighlightedPlate(this.props.plate.id);
          }}
        >
          <div className="img-col plate-thumb">
            {
              <img
                src="/plate-thumbnail.svg"
                alt="thumbnail image"
                className="thumbnail-img"
              />
            }
          </div>
          <div className="display-card-row align-items-center pt-0">
            <div className="display-card-header">
              <label title={this.props.plate.id}>{this.props.plate.id} </label>
            </div>
            <div
              className="display-card-more-action"
              onClick={(e) => e.stopPropagation()}
            >
              <DisplayPlateOptions {...{ displayPlate: this.props.plate }} />
            </div>
          </div>
          <div className="display-card-row">
            <div className="display-card-sub-header">
              <>
                <div className="details">
                  <label>Created Date:</label>
                  <span className="data-format">
                    {this.props.plate.created_at
                      ? moment(new Date(this.props.plate.created_at)).format(
                          "MMMM Do YYYY, HH:mm:ss A"
                        )
                      : "Not Available"}{" "}
                  </span>
                </div>
                <div className="details">
                  <label>Created By:</label>
                  <span>{this.props.plate.created_by}</span>
                </div>
              </>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const SearchPlateCardView = withRouter(connect(Props, Actions)(Component));

export default SearchPlateCardView;
