import { NotificationManager } from "react-notifications";
import { IPart, IState } from '../../../Interfaces';
import { IPartInfo } from "../../../../interfaces/IPartInfo";
import { setPlate } from '../../../Instances';
import * as THREE from "three";
import { Vector3 } from "three";
import ACTION_TYPES from '../../../actionTypes';
//import onAddSelectedPartsToAddedParts from '../../Parts/AddToPlate/addPartsToAddedParts';
//import addPartFail from '../../../Actions/Parts/Add/addPart';
import onNewPlateCloseDialog from '../../../Actions/Plates/New/newPlateCloseDialog';
//import { IWorkspaceMeshManager } from "../../../Components/WorkspaceMeshManager";

const onAddPartFail = (errorMessage: string) => {
  NotificationManager.error(errorMessage);
  return {
    type: ACTION_TYPES.PART.ADD.FAIL,
    payload: errorMessage,
  };
};


const onPartReplicate = (ids, numOfParts, partsSpacing) => {
  return async (dispatch, getState) => {
    try {
      console.time("Part Repeat")
      // Enforce minimum values for number of parts and spacing between the parts.
      if (numOfParts === null || numOfParts < 2)
        numOfParts = -1
      if (partsSpacing === null || partsSpacing < 1)
        partsSpacing = 10
      let state = getState() as IState;
      //get latest configurations of part
      if (state.data.workspace.addedParts === null || !(state.data.workspace.addedParts.length > 0))
      {
        throw new Error('No part to replicate!');
      }

      let groupBox = new THREE.Box3();
      for (var i = 0; i < state.data.workspace.state.build.parts.length; i++) {
        const part = state.data.workspace.state.build.parts[i] as IPartInfo;
        if (part.mesh.userData.intersectsOtherMesh)
          throw new Error('Part intersects with others!');
        const partBox = new THREE.Box3().setFromObject(part.mesh, true);
        groupBox.union(partBox);
      }
      const groupSize = [(groupBox.max.x - groupBox.min.x), (groupBox.max.y - groupBox.min.y)]

      // Copied from WorkspaceScene
      const buildVolume = state.data.workspace.state.build.machineConfig?.originalJson?.Build?.BuildVolumes[0];
      let minVector = new THREE.Vector3(
        buildVolume?.Size ? -(buildVolume.Size.x / 2) : buildVolume?.Min ? buildVolume?.Min.x : -75,
        buildVolume?.Size ? -(buildVolume.Size.y / 2) : buildVolume?.Min ? buildVolume?.Min.y : -75,
        buildVolume?.Size ? 0 : buildVolume?.Min ? buildVolume?.Min.z : 0,
      )
      let maxVector = new THREE.Vector3(
        buildVolume?.Size ? (buildVolume.Size.x / 2) : buildVolume?.Max ? buildVolume?.Max.x : 75,
        buildVolume?.Size ? (buildVolume.Size.y / 2) : buildVolume?.Max ? buildVolume?.Max.y : 75,
        buildVolume?.Size ? (buildVolume.Size.z) : buildVolume?.Max ? buildVolume?.Max.z : 100,
      )
      const buildAreaVolume = new THREE.Box3(minVector, maxVector)
      const plateSize = [(buildAreaVolume.max.x - buildAreaVolume.min.x), (buildAreaVolume.max.y - buildAreaVolume.min.y)]
      const locs = autoPack(groupSize, plateSize, partsSpacing, numOfParts);

      if (locs.length > 1) {
        const parts = state.data.workspace.state.build.parts;
        const addedParts = state.data.workspace.addedParts;
        let newState = state;
        newState.data.workspace.state.build.parts = [] as IPartInfo[];
        newState.data.workspace.addedParts = [] as IPart[];

        const bvCenter = new THREE.Vector3();
        buildAreaVolume?.getCenter(bvCenter);
        const groupCenter = new THREE.Vector3();
        groupBox.getCenter(groupCenter);

        ids.partIds = addedParts.map(p => p.id)
        ids.partConfigurationIds = state.data.workspace.addedPartsSelectedPartConfigurations.map(c => c.id);
        ids.partSlicerConfigurationIds = state.data.workspace.addedPartsSelectedPartSlicerConfigurations.map(c => c.id);
        ids.plateConfigurationIds = state.data.workspace.selectedConfigurations.map(c => c.id);

        if(ids.partConfigurationIds.length > ids.partIds.length){
          ids.partConfigurationIds.splice((ids.partConfigurationIds.length-1), (ids.partConfigurationIds.length - ids.partIds.length))
          state.data.workspace.addedPartsSelectedPartConfigurations.splice((state.data.workspace.addedPartsSelectedPartConfigurations.length-1), 
            (state.data.workspace.addedPartsSelectedPartConfigurations.length - ids.partIds.length))
        }
        if(ids.partSlicerConfigurationIds.length > ids.partIds.length)
        {
          ids.partSlicerConfigurationIds.splice((ids.partSlicerConfigurationIds.length-1), (ids.partSlicerConfigurationIds.length - ids.partIds.length))
          state.data.workspace.addedPartsSelectedPartSlicerConfigurations.splice((state.data.workspace.addedPartsSelectedPartSlicerConfigurations.length-1), 
            (state.data.workspace.addedPartsSelectedPartSlicerConfigurations.length - ids.partIds.length))
        }

        const tmpPartConfig = ids.partConfigurationIds
        const tmpPartSlicerConfig = ids.partSlicerConfigurationIds

        NotificationManager.info(`Creating ${locs.length * parts.length} parts...`);
        for (let count = 0, added = 0; count < locs.length; count++) {
          if (count > 0){
            ids.partConfigurationIds = ids.partConfigurationIds.concat(tmpPartConfig)
            ids.partSlicerConfigurationIds = ids.partSlicerConfigurationIds.concat(tmpPartSlicerConfig)
          }
          newState = setPlate(newState, ids, false) as IState;
          for (let i = 0; i < parts.length; i++, added++) {
            const originalPart = parts[i] as IPartInfo;
            const position = originalPart.properties.translate;
            const vectorForMove = new THREE.Vector3().addVectors(position, bvCenter).sub(groupCenter)
            const tmpPart = newState.data.workspace.state.build.parts[added] as IPartInfo;
            tmpPart.properties.translate = { x: vectorForMove.x + locs[count][0], y: vectorForMove.y + locs[count][1], z: position.z };
            tmpPart.properties.rotate = originalPart.properties.rotate;
            tmpPart.properties.scale = originalPart.properties.scale;
            if (originalPart.properties.DualZ)
              tmpPart.properties.DualZ = originalPart.properties.DualZ;
            tmpPart.userData.newPart = false;
            newState.data.workspace.state.build.parts[added] = tmpPart;
          }
        }
        state = newState;
      }
      else {
        throw new Error('Cannot accomdate more replicate on the plate!');
      }
      console.timeEnd("Part Repeat")
      dispatch(onNewPlateCloseDialog())
    } catch (ex: any) {
      const errorMessage = `${ex.toString()}` as string;
      console.error(ex.toString());
      dispatch(onAddPartFail(errorMessage));
    }
  }
}

/**The new function is defined as autoPack(partSize, plateSize, spacing, total), 
   * where partSize and plateSize are arrays of the x/y sizes of the scaled part and build volume.  
   * spacing is the distance between parts.  total is the requested count.  
   * It returns an array of [x, y] locations of the center points where you'll align the part x/y center with.  
   * The bottom of the parts should always be on the Z=0 plane. */
const autoPack = (partSize, plateSize, spacing, total) => {
  const count = {
    total: total,
    x: 0,
    y: 0,
  };
  for (let x = partSize[0]; x <= plateSize[0]; x = x + partSize[0] + spacing)
    count.x = count.x + 1;
  for (let y = partSize[1]; y <= plateSize[1]; y = y + partSize[1] + spacing)
    count.y = count.y + 1;
  adjust_count(count);

  const x_size = count.x * partSize[0] + (count.x - 1) * spacing;
  const y_size = count.y * partSize[1] + (count.y - 1) * spacing;
  // lower-right
  const ctr = [(x_size - partSize[0]) / 2, (partSize[1] - y_size) / 2];

  let locs = new Array();
  for (let i = 0, n = count.total, x = 0; i < count.x && n > 0; i++) {
    for (let j = 0, y = 0; j < count.y && n > 0; j++, n--) {
      locs.push([ctr[0] + x, ctr[1] + y]);
      y = y + partSize[1] + spacing
    }
    x = x - partSize[0] - spacing;
  }

  return locs;
}

const adjust_count = (count) => {
  const max_count = count.x * count.y;
  // Use negative value for max count
  if (count.total < 0)
    count.total = max_count
  else if (count.total < 1)
    count.total = count.x = count.y = 0;
  else if (count.total < max_count) {
    count.x = Math.ceil(count.total / count.y);
    if (count.total < (count.x * count.y)) {
      if (count.x > 1) {
        for (
          let fill = count.y - (count.total % count.y);
          fill >= count.x;
          fill = fill - count.x
        )
          count.y = count.y - 1;
      }
      else
        count.y = count.total;
    }
  }
  else if (count.total > max_count)
    count.total = max_count;
}

export default onPartReplicate;
