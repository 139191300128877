import React from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, DropdownButton, Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { onMovePartCloseDialog, onMovePartNameChange, onMovePart } from '../../../Actions/Parts';
import { IState, IMovePartModalStatus, IDisplayFolder } from '../../../Interfaces';
import { onHighlightFolder } from '../../../Actions/Folders';
import { getDisplayFolders } from '../../../Mappers';

const Props = (state: IState, ownProps: any) => {

	const displayFolders = getDisplayFolders(state) as IDisplayFolder[];
	const movePartModalStatus = state.data.modals.movePartModalStatus as IMovePartModalStatus;

	const props = {
		displayFolders: displayFolders,
		highlightedFolderId: state.data.folders.highlightedFolderId,
		isModalOpen: movePartModalStatus.isOpen,
		inputText: movePartModalStatus.inputText,
		hasError: movePartModalStatus.hasError,
		errorMessage: movePartModalStatus.errorMessage,
		isValid: movePartModalStatus.isValid,
	};

	return props;
};

const Actions = {
	onHighlightFolder: onHighlightFolder,
	onMovePartCloseDialog: onMovePartCloseDialog,
	onMovePartNameChange: onMovePartNameChange,
	onMovePart: onMovePart,
};

class Component extends React.Component<any, any> {
	public render() {
		return (
			<>
				{
					this.props.isModalOpen ?
						(
							<>
								<Modal
									show={this.props.isModalOpen}
									onHide={() => {
										this.props.onMovePartCloseDialog();
										this.props.onHighlightFolder('');
									}}
									backdrop='static'
									keyboard={false}
									dialogClassName='partsDialog'
									aria-labelledby='contained-modal-title-vcenter'
									centered
								>
									<Modal.Header closeButton>
										<Modal.Title>Move</Modal.Title>
									</Modal.Header>
									<Modal.Body>
										<div className='each-dialog-content'>
											<div className='options'>
												<div className='plate-displayFolders option'>
													<label>Select the folder</label>

													<DropdownButton id='dropdown-basic-button' title='Select'>
														<Dropdown.Item
															onClick={() => {
																this.props.onMovePartNameChange('');
																this.props.onHighlightFolder('');
															}}
														>
															none
														</Dropdown.Item>
														{
															this.props.displayFolders.map(df => {
																return (
																	<React.Fragment key={df.id}>
																		<Dropdown.Item
																			onClick={() => {
																				this.props.onMovePartNameChange(df.name);
																				this.props.onHighlightFolder(df.id);
																			}}
																		>
																			{df.name}
																		</Dropdown.Item>
																	</React.Fragment>
																);
															})
														}
													</DropdownButton>
												</div>
											</div>
											<div className='margin-t-10'>
												Name : {this.props.highlightedFolderId}
											</div>
											<div
												className='invalid-feedback d-block'
												hidden={!this.props.hasError}>
												{this.props.errorMessage}
											</div>
										</div>
									</Modal.Body>
									<Modal.Footer className="justify-content-center">
										<Button
											className='btn-primary'
											onClick={() =>  {this.props.onMovePart(); this.props.onMovePartCloseDialog();}}
											autoFocus
										>
											Move Part
										</Button>
									</Modal.Footer>
								</Modal>
							</>
						)
						:
						null
				}
			</>
		);
	}
}

const MovePart = withRouter(connect(Props, Actions)(Component));

export default MovePart;
