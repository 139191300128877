import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { DisplayBuildOptions } from "..";
import { IState } from "../../Interfaces";
import API, { graphqlOperation } from "@aws-amplify/api";
import Amplify, { Auth } from "aws-amplify";
import { onNavigateToBuildReviewPage, onNavigateToBuildPreviewPage } from "../../Actions/Home";
import { onSelectPart } from "../../Actions/Parts";
import { getBuild } from "../../../graphql/queries";
import { NotificationManager } from "react-notifications";
import moment from 'moment';
import onGetHighlightedBuild from "../../Actions/Search/highlightBuild";
import  inprogress from "./inprogress.svg"

const Props = (state: IState, ownProps: any) => {
	const props = {
		highlightBuild: state.data.search.highlightedBuildData ? state.data.search.highlightedBuildData : null
	};

	return props;
};

const Actions = {
	onNavigateToBuildReviewPage: onNavigateToBuildReviewPage,
	onNavigateToBuildPreviewPage: onNavigateToBuildPreviewPage,
	onSelectPart: onSelectPart,
	onGetHighlightedBuild: onGetHighlightedBuild
};

class Component extends React.Component<any, any> {
  isConfigDisabled = false;
  isProcessing: boolean = false;
  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    Auth.currentAuthenticatedUser().then((user) => {
      const groups =
        user.signInUserSession.accessToken.payload["cognito:groups"] || [];
      if (groups[0] == "Operator") {
        this.isConfigDisabled = true;
      } else {
        this.isConfigDisabled = false;
      }
    });
  }

  handleBuildCardClickHandler = async (id) => {
    if (this.isProcessing) return;
    this.isProcessing = true;
    if (this.isProcessing) {
      await this.props.onGetHighlightedBuild(id);
      const {
        highlightBuild,
        history,
        onNavigateToBuildReviewPage,
        isConfigDisabled,
      } = this.props;

      if (Object.keys(highlightBuild).length !== 0) {
        const status = highlightBuild.current_status;

        if (
          status === "completed" ||
          status === "fail" ||
          (status === "failed" && !isConfigDisabled)
        ) {
          onNavigateToBuildReviewPage(highlightBuild.id, history);
        } else {
          NotificationManager.error(
            "The selected build is in progress and cannot be opened."
          );
        }
      }
    }
  };

  public render() {
    return (
      <>
        <div
          className="build-card-view p-0 build-card"
          onClick={() => this.handleBuildCardClickHandler(this.props.build.id)}
          id={this.props.build.id}
          key={this.props.build.id}
        >
          <div style={{ height: "90px" }} className="img-col plate-thumb">
            {
              <img
                src="/build-thumbnail.svg"
                alt="thumbnail image"
                className="thumbnail-img"
              />
            }
          </div>
          <div className="display-card-row text-center pt-0">
            <div className="display-card-sub-header status-display">
              {this.props.build.moho_version ? (
                <label>{this.props.build.moho_version}</label>
              ) : (
                <label className="invisible">_</label>
              )}

              {(this.props.build?.current_status === "completed" ||
                this.props.build?.status === "completed") && (
                <span className="capitalise-text font-weight-bold valign">
                  <img
                    src="/img/slicing-completed.svg"
                    alt="Completed Status Icon"
                  />
                  {" " + "Slicing Completed"}
                </span>
              )}

              {(this.props.build?.current_status === "failed" ||
                this.props.build?.status === "failed") && (
                <span className="capitalise-text font-weight-bold valign">
                  <img src="/img/slicing-failed.svg" alt="Failed Status Icon" />
                  {" " + "Slicing Failed"}
                </span>
              )}
              {(this.props.build?.current_status === "canceled" ||
                this.props.build?.status === "canceled") && (
                <span className="capitalise-text font-weight-bold valign">
                  <img
                    src="/img/slicing-failed.svg"
                    alt="Canceled Status Icon"
                  />
                  {" " + "Slicing Canceled"}
                </span>
              )}
              {(this.props.build?.current_status === "processing" ||
                this.props.build?.current_status === "waiting" ||
                this.props.build?.current_status === "processing" ||
                this.props.build?.current_status === "waiting" ||
                this.props.build?.current_status?.includes("Progress")) && (
                <div className="px-3 w-50">
                  {this.props.build?.current_status === "processing" ||
                  this.props.build?.current_status?.current_status?.includes(
                    "Progress"
                  ) ? (
                    <span className="capitalise-text font-weight-bold valign">
                      <img
                        style={{ width: "30px" }}
                        className="pl-0"
                        src={inprogress}
                        alt="in progress"
                      />
                      {" " + "processing"}
                    </span>
                  ) : (
                    <label className="pl-0">Initializing Build</label>
                  )}
                </div>
              )}
              <span></span>
            </div>
          </div>
          <div className="display-card-row align-items-center pt-0">
            <div className="display-card-header">
              <label title={this.props.build.id}>{this.props.build.id}</label>
            </div>
            <div
              className="display-card-more-action"
              onClick={(e) => e.stopPropagation()}
            >
              <DisplayBuildOptions displayBuild={this.props.build} />
            </div>
          </div>
          <div className="display-card-row">
            <div className="display-card-sub-header">
              <>
                <div className="details">
                  <label>Created Date:</label>
                  <span className="data-format">
                    {this.props.build.created_at
                      ? moment(new Date(this.props.build.created_at)).format(
                          "MMMM Do YYYY, HH:mm:ss A"
                        )
                      : "Not Available"}{" "}
                  </span>
                </div>
                <div className="details">
                  <label>Created By</label>
                  <span>{this.props.build.created_by}</span>
                </div>
              </>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const SearchBuildCardView = withRouter(connect(Props, Actions)(Component));

export default SearchBuildCardView;
