import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { onHighlightPart, onSelectPart, onUnselectPart } from '../../Actions/Parts';
import { IDisplayPlate, IPlate, IState } from '../../Interfaces';
import DisplayPartOptions from '../Prepare/Parts/DisplayPartOptions';
import moment from 'moment';

const Props = (state: IState, ownProps: any) => {
    const props = {
        isSelected: ownProps.isSelected,
    };

    return props;
};

const Actions = {
    onSelectPart: onSelectPart,
    onUnselectPart: onUnselectPart,
    onHighlightPart:onHighlightPart
};

class Component extends React.Component<any, any> {
    public render() {
        return (
            <>
                {
                    this.props.part ?
                        (
                            <div
                                className={`is-selected position-relative p-0`}
                                id={`part-${this.props.part.index}`}
                                style={{ paddingLeft: '10px' }}
                                onClick={(e) => {
                                if(!this.props.part.isSelected) {
                                    this.props.onSelectPart(this.props.part.id);
                                }
                                e.stopPropagation();
                            }}
                            >
                                <div className='display-card-select-action pt-2 pl-2 position-absolute'>
                                    <div className='options right-justify'>
                                        <>
                                            {
                                                <>
                                                    <div className='check option'>
                                                        <img className='icon uncheck' style={{ margin: '0px', padding: '4px' }} alt=''></img>
                                                    </div>
                                                </>
                                            }
                                        </>
                                    </div>
                                </div>
                                <div className='img-col plate-thumb'>
                                    {
                                        <img src="/part-thumbnail.svg" alt="thumbnail image" className='thumbnail-img plate-thumb' />
                                    }
                                </div>
                                <div className='display-card-row justify-content-center pt-0'>
                                    <div className='display-card-header d-flex'>
                                     
                                        <label title={this.props.part.id.split('/').pop()}>{this.props.part.id.split('/').pop()} </label>
                                    </div>
                                    <div className="display-card-more-action"
                                        onClick={(e) => {
                                            this.props.onHighlightPart(this.props.part.id); 
                                            e.stopPropagation();
                                            }}>
                                        <DisplayPartOptions {...{ displayPart: this.props.part }} />
                                    </div>
                            </div>
                                <div className='display-card-row'>
                                    <div className='display-card-sub-header'>
                                        <>
                                            <div className='details'>
                                                <label>Created Date:</label>
                                                <span className="data-format">{(this.props.part.created_at ? moment(new Date(this.props.part.created_at)).format('MMMM Do YYYY, HH:mm:ss A') : 'Not Available')} </span>
                                            </div>
                                            <div className='details'>
                                                <label>Created By:</label>
                                                <span>{this.props.part.created_by}</span>
                                            </div>
                                        </>
                                    </div>
                                </div>
                            </div>
                        ) :
                        (
                            null
                        )
                }
            </>
        )
    }




}

const SearchPartCardView = withRouter(connect(Props, Actions)(Component));

export default SearchPartCardView;
